import {AbstractRouter} from "../Router";
import {GamesRealtimeReport} from "./Games.RealtimeReport";
import {DailyReport} from "../DailyReport";
import {GamesUserReport} from "./Games.UserReport";
import {GamesUserLtzReport} from "./Games.UserLtzReport";
import {GamesRevenueReport} from "./Games.RevenueReport";
import {GamesRevenueLtzReport} from "./Games.RevenueLtzReport";
import {GamesTopupReport} from "./Games.TopupReport";
import {GamesRealtimeTopupReport} from "./Games.RealtimeTopupReport";
import {Properties} from "../../common/properties";

class GamesRouter extends AbstractRouter {

    getConfigDicts() {
        let realtimeConfigDicts = this.getRealtimeConfigDicts();
        let dailyCcuConfigDicts = this.getDailyCcuConfigDicts();
        let dailyUserConfigDicts = this.getDailyUserConfigDicts();
        let dailyUserLtzConfigDicts = this.getDailyUserLtzConfigDicts();
        let dailyRevenueConfigDicts = this.getDailyRevenueConfigDicts();
        let dailyRevenueLtzConfigDicts = this.getDailyRevenueLtzConfigDicts();
        let realtimeConsumptionConfigDicts = this.getRealtimeConsumptionConfigDicts();
        let dailyConsumptionConfigDicts = this.getDailyConsumptionConfigDicts();
        let dailyTopupConfigDicts = this.getDailyTopupConfigDicts();
        let realtimeTopupConfigDicts = this.getRealtimeTopupConfigDicts();
        return [
            ...realtimeConfigDicts,
            ...realtimeConsumptionConfigDicts,
            ...realtimeTopupConfigDicts,
            ...dailyCcuConfigDicts,
            ...dailyUserConfigDicts,
            ...dailyUserLtzConfigDicts,
            ...dailyRevenueConfigDicts,
            ...dailyRevenueLtzConfigDicts,
            ...dailyConsumptionConfigDicts,
            ...dailyTopupConfigDicts,
        ]
    }

    getRealtimeConfigDicts() {
        let realtimeConfigDicts = [
            {metric: 'ccu', reportLabel: 'CCU'},
            {metric: 'user_ccu', reportLabel: 'User CCU'},
            {metric: 'streamer_ccu', reportLabel: 'Streamer CCU'},
            {metric: 'active', reportLabel: 'Active'},
            {metric: 'active_ltz', reportLabel: 'Active LTZ'},
            {metric: 'revenue_gross', reportLabel: 'Gross Revenue',
            },
            {metric: 'revenue_net', reportLabel: 'Net Revenue',
            },
            {metric: 'revenue_gross_ltz', reportLabel: 'Gross Revenue LTZ',
            },
            {metric: 'revenue_net_ltz', reportLabel: 'Net Revenue LTZ',
            },
        ];
        return realtimeConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.realtime,
                reportClass: GamesRealtimeReport,
                productGroup: 'games_api',
                ...configDict
            }
        });
    }

    getRealtimeConsumptionConfigDicts() {
        let realtimeConsumptionConfigDicts = [
            {metric: 'revenue_gross', reportLabel: 'Gross Revenue',
            },
            {metric: 'revenue_net', reportLabel: 'Net Revenue',
            },
        ];
        return realtimeConsumptionConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.realtime,
                reportClass: GamesRealtimeReport,
                productGroup: 'consumption_api',
                ...configDict
            }
        });
    }

    getRealtimeTopupConfigDicts() {
        let realtimeTopupConfigDicts = [
            {metric: 'topup', reportLabel: 'Topup',
            },
        ];
        return realtimeTopupConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.realtime,
                reportClass: GamesRealtimeTopupReport,
                productGroup: 'topup_api',
                ...configDict
            }
        });
    }

    getDailyCcuConfigDicts() {
        let dailyCcuConfigDicts = [
            {metric: 'ccu', reportLabel: 'PCU'},
            {metric: 'user_ccu', reportLabel: 'User PCU'},
            {metric: 'streamer_ccu', reportLabel: 'Streamer PCU'},
        ];
        return dailyCcuConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'games_api',
                ...configDict
            }
        });
    }

    getDailyUserConfigDicts() {
        let dailyUserConfigDicts = [
            {metric: 'active', reportLabel: 'User'}
        ];
        return dailyUserConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: GamesUserReport,
                productGroup: 'games_api',
                ...configDict
            }
        });
    }

    getDailyUserLtzConfigDicts() {
        let dailyUserLtzConfigDicts = [
            {metric: 'active_ltz', reportLabel: 'User LTZ'}
        ];
        return dailyUserLtzConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: GamesUserLtzReport,
                productGroup: 'games_api',
                ...configDict
            }
        });
    }

    getDailyRevenueConfigDicts() {
        let dailyRevenueConfigDicts = [
            {metric: 'revenue_gross', reportLabel: 'Gross Revenue',
            },
            {metric: 'revenue_net', reportLabel: 'Net Revenue',
            },
        ];
        return dailyRevenueConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: GamesRevenueReport,
                productGroup: 'games_api',
                ...configDict
            }
        });
    }

    getDailyRevenueLtzConfigDicts() {
        let dailyRevenueLtzConfigDicts = [
            {metric: 'revenue_gross_ltz', reportLabel: 'Gross Revenue LTZ',
            },
            {metric: 'revenue_net_ltz', reportLabel: 'Net Revenue LTZ',
            },
        ];
        return dailyRevenueLtzConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: GamesRevenueLtzReport,
                productGroup: 'games_api',
                ...configDict
            }
        });
    }

    getDailyConsumptionConfigDicts() {
        let dailyConsumptionConfigDicts = [
            {metric: 'revenue_gross', reportLabel: 'Gross Revenue',
            },
            {metric: 'revenue_net', reportLabel: 'Net Revenue',
            },
        ];
        return dailyConsumptionConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
                productGroup: 'consumption_api',
                ...configDict
            }
        });
    }

    getDailyTopupConfigDicts() {
        let dailyTopupConfigDicts = [
            {metric: 'topup', reportLabel: 'Topup',
            }
        ];
        return dailyTopupConfigDicts.map(configDict => {
            return {
                frequency: Properties.frequencyBit.daily,
                reportClass: GamesTopupReport,
                productGroup: 'topup_api',
                ...configDict
            }
        });
    }

    getMatchUrl() {
        return '/games';
    }
}

export {GamesRouter};