import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import _ from 'lodash';
import {UserMetaUtils, Utils} from "../common/utils";


class RouterConfig {
    constructor(configDict, matchUrl) {
        this.metric = configDict.metric;
        this.reportLabel = configDict.reportLabel;
        this.extraProps = _.get(configDict, 'extraProps', {});
        this.frequency = configDict.frequency;
        this.reportClass = configDict.reportClass;
        this.productGroup = configDict.productGroup;
        this.matchUrl = matchUrl;
        this.valueProps = {}

    }

    getMetricKey(metricConfig) {
        return metricConfig['key']
    }

    setValueProps(userMeta, product) {
        let productConfigs = this.getProductConfigsFromUserMeta(userMeta);
        productConfigs.forEach(productConfig => {
            if (_.get(productConfig, 'product', '') !== product) {
                return
            }
            _.get(productConfig, 'app_metric_configs', []).forEach(
                metricConfig => {
                    let metricKey = this.getMetricKey(metricConfig)
                    if (metricKey === this.metric) {
                        this.valueProps = metricConfig.value_props
                    }
                    _.get(metricConfig, 'subcategories', []).forEach(subcategory => {
                        if (subcategory['key'] === this.metric && !_.isEmpty(subcategory.value_props)) {
                            this.valueProps = subcategory.value_props
                        }
                    })
                }
            )
        })
    }

    renderRoutesFromMeta(userMeta, product) {
        this.setValueProps(userMeta, product)
        let productConfigs = this.getProductConfigsFromUserMeta(userMeta);
        return productConfigs.map(productConfig => this.renderRouteFromProductConfig(productConfig));

    }

    getProductConfigsFromUserMeta(userMeta) {
        let productConfigs = [];
        _.forEach(userMeta.meta_data, (metaConfig) => {
            let productConfig = userMeta.data[metaConfig.index];
            if (productConfig.product_group !== this.productGroup || !this.doesMetaIncludeMetric(this.metric, metaConfig)) {
                return;
            }
            productConfigs.push(productConfig);
        });
        return productConfigs;
    }

    doesMetaIncludeMetric(metric, metaConfig) {
        let metaMetricKey = UserMetaUtils.getMetaMetricKey(metric, this.frequency);
        return metaMetricKey in metaConfig.metrics;
    }

    renderRouteFromProductConfig(productConfig) {
        let path = Utils.getUrlPath({
            matchUrl: this.matchUrl,
            metric: this.metric,
            product: productConfig.product,
            frequencyBit: this.frequency
        });
        return <Route exact path={path} key={path}
                      render={(props) => this.renderFromPropsAndConfig(props, productConfig)}/>;
    }

    renderFromPropsAndConfig(props, productConfig) {
        return (
            <this.reportClass {...props}
                              extraProps={this.extraProps}
                              valueProps={this.valueProps}
                              metric={this.metric}
                              reportLabel={this.reportLabel}
                              frequency={this.frequency}
                              productConfig={productConfig}
            />
        )
    }

}


class AbstractRouter extends Component {

    render() {
        let configDicts = this.getConfigDicts();
        let matchUrl = this.getMatchUrl();
        let routes = _.flatMap(configDicts, (configDict) => {
            let routerConfig = this.createRouterConfig(configDict, matchUrl);
            return routerConfig.renderRoutesFromMeta(this.props.userMeta, this.props.product);
        });
        return (
            <React.Fragment>
                {routes}
            </React.Fragment>
        );
    }

    getConfigDicts() {
        return [];
    }

    getMatchUrl() {
        return '';
    }

    createRouterConfig(configDict, matchUrl) {
        return new RouterConfig(configDict, matchUrl);
    }
}

export {AbstractRouter, RouterConfig};