import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";
import {BmgGoSidebar} from "./BmgGo.Sidebar";
import {BmgEditorSidebar} from "./BmgEditor.Sidebar";


class GamesSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    getGames() {
        let games = [];
        _.forEach(this.props.userMeta.data, (productConfig) => {
            if (_.includes(['games_api', 'consumption_api', 'topup_api'], productConfig.product_group)) {
                if (_.includes(['bmg_go', 'bmg_editor', 'bmg_go_mobile', 'bmg_go_pc', 'bmg_editor_mobile',
                    'bmg_editor_pc', 'bmg_go_bw', 'bmg_go_sb', 'bmg_go_ew', 'bmg_go_jb', 'bmg_go_ht2'], productConfig.product)) {
                    return
                }
                games.push(productConfig);
            }
        });
        return games;
    }

    hasPermission(game) {
        return this.props.userMeta.data.some((productConfig) => {
            return productConfig.product_group === 'games_api' && productConfig.product === game;
        });
    }


    makeLinks(gameConfig) {
        return _.filter([
            {config: gameConfig, metric: 'ccu', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime CCU'},
            {config: gameConfig, metric: 'ccu', frequencyBit: Properties.frequencyBit.daily, label: 'PCU'},
            {config: gameConfig, metric: 'user_ccu', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime User CCU'},
            {config: gameConfig, metric: 'user_ccu', frequencyBit: Properties.frequencyBit.daily, label: 'User PCU'},
            {config: gameConfig, metric: 'streamer_ccu', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Streamer CCU'},
            {config: gameConfig, metric: 'streamer_ccu', frequencyBit: Properties.frequencyBit.daily, label: 'Streamer PCU'},
            {config: gameConfig, metric: 'active', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Active'},
            {config: gameConfig, metric: 'active', frequencyBit: Properties.frequencyBit.daily, label: 'User'},
            {config: gameConfig, metric: 'active_ltz', frequencyBit: Properties.frequencyBit.realtime, label: gameConfig.product.includes('df') ? 'Realtime Active UTC' : 'Realtime Active LTZ'},
            {config: gameConfig, metric: 'active_ltz', frequencyBit: Properties.frequencyBit.daily, label: gameConfig.product.includes('df') ? 'User UTC' : 'User LTZ'},
            {config: gameConfig, metric: 'revenue_gross', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Revenue (Gross)'},
            {config: gameConfig, metric: 'revenue_gross', frequencyBit: Properties.frequencyBit.daily, label: 'Revenue (Gross)'},
            {config: gameConfig, metric: 'revenue_net', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Revenue (Net)'},
            {config: gameConfig, metric: 'revenue_net', frequencyBit: Properties.frequencyBit.daily, label: 'Revenue (Net)'},
            {config: gameConfig, metric: 'revenue_gross_ltz', frequencyBit: Properties.frequencyBit.realtime, label: gameConfig.product.includes('df') ? 'Realtime Revenue (Gross) UTC' :'Realtime Revenue (Gross) LTZ'},
            {config: gameConfig, metric: 'revenue_gross_ltz', frequencyBit: Properties.frequencyBit.daily, label: gameConfig.product.includes('df') ? 'Revenue (Gross) UTC' : 'Revenue (Gross) LTZ'},
            {config: gameConfig, metric: 'revenue_net_ltz', frequencyBit: Properties.frequencyBit.realtime, label: gameConfig.product.includes('df') ? 'Realtime Revenue (Net) UTC' : 'Realtime Revenue (Net) LTZ'},
            {config: gameConfig, metric: 'revenue_net_ltz', frequencyBit: Properties.frequencyBit.daily, label: gameConfig.product.includes('df') ? 'Revenue (Net) UTC' : 'Revenue (Net) LTZ'},
            {config: gameConfig, metric: 'topup', frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Topup'},
            {config: gameConfig, metric: 'topup', frequencyBit: Properties.frequencyBit.daily, label: 'Topup'},

        ], ({config, metric, frequencyBit}) => this.hasMetric(config.product, metric, frequencyBit));
    }

    render() {
        let games = this.getGames();
        return (
            <React.Fragment>
                {_.map(games, (gameConfig) => (<Section key={'section' + gameConfig.label}
                                                        productType={'games'}
                                                        links={this.makeLinks(gameConfig)}
                                                        label={gameConfig.sidebar_label}
                                                        additionalData={this.props.additionalData}
                />))
                }
                {this.hasPermission('bmg_go')? <BmgGoSidebar {...this.props}/> : <React.Fragment/>}
                {this.hasPermission('bmg_editor')? <BmgEditorSidebar {...this.props}/>: <React.Fragment/>}
            </React.Fragment>
        )
    }
}

export {GamesSidebar};