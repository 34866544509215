import {RealtimeReport} from "../RealtimeReport";
import _ from "lodash";

class GamesRealtimeReport extends RealtimeReport {

    makeLabel(appLabel, reportLabel) {
        const label = super.makeLabel(appLabel, reportLabel);
        return appLabel.includes('DF') ? label.replace(/LTZ/g, 'UTC') : label;
    }
}

export {GamesRealtimeReport};