import _ from 'lodash';
import {GamesRevenueReport} from "./Games.RevenueReport";


class GamesRevenueLtzReport extends GamesRevenueReport {
    getMetrics() {
        return super.getMetrics().map(metric => `${metric}_ltz`);
    }

    updateKeysWithLtz(obj) {
        return _.reduce(obj, function(result, value, key) {
            result[`${key}_ltz`] = value;
            return result;
        }, {});
    }

    getMetricHeader() {
        return this.updateKeysWithLtz(super.getMetricHeader());
    }

    getMetricFormatter() {
        return this.updateKeysWithLtz(super.getMetricFormatter());
    }

    makeLabel(appLabel, reportLabel) {
        const label = super.makeLabel(appLabel, reportLabel);
        return appLabel.includes('DF') ? label.replace(/LTZ/g, 'UTC') : label;
    }
}

export {GamesRevenueLtzReport};
